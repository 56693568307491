<template>
  <v-container class="d-flex justify-center">
    <Login
      class="login"
      :redirectParams="redirectParams"
      style="max-width:600px"
    />
  </v-container>
</template>
<script>
import Login from "@/components/Login.vue";
export default {
  name: "LoginView",
  components: {
    Login
  },
  props: {
    redirectParams: { type: Object, required: false }
  }
};
</script>
